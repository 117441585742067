
.widget-container,
.widget-container > div {
  height: 100%;
  width: 100%;
}


.assetSelectionButton span
{
  white-space:nowrap;
}


.assetSelectionButton
{
  margin-left:0.5vh;
  margin-right:0.5vh;
  min-width: auto; 
}

.customTickerTape iframe
{
  height: 100% !important; 
}

.customTickerTape > div > div
{
  height: 6.1vh !important;
}