.bigParameterBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
  justify-content: space-evenly;
}

.bigParameterBoxTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  margin-left: 2%;
  margin-right: 2%;
}

.mediumParameterBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;
  gap: 30px;
}

.mediumParameterBoxTop {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  align-content: flex-start;
  margin-left: 10%;
  margin-right: 10%;
}

.smallParameterBox {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.compactParameterBox {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 10px;
  margin-bottom: 10px;
}

.columnParameterBox {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.columnParameterBoxTop {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.columnParameterBoxLeft {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.cardBox {
  display: flex;
  flex-wrap: wrap;
}

.card-4 {
  flex: 1 0 40%;
  margin: 5px;
  padding: 1px;
}

.confirmCancelParameterBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.confirmCancelParameterBox button {
  margin-left: 5px;
  margin-right: 5px;
}

.selectedButton {
  border: 2px solid var(--color-input);
}

.selectedButton button:focus,
.selectedButton a:focus {
  outline: none;
}

.with-margin {
  border: 1px solid var(--color-input);
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 1px;
  margin-right: 2px;
  background-color: var(--background-color-3);
  cursor: pointer;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.line-arrangement {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.line-arrangement-spread {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.line-arrangement-element {
  margin-left: 5%;
  margin-right: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.line-arrangement .bp3-form-group {
  margin-bottom: 0;
}

.clickableText
{
  cursor: pointer;
  color: var(--color-2);
  font-size: var(--input-font-size);
}


.dimmed {
  position: relative;
}

.dimmed:after {
  content: " ";
  z-index: 0;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: var(--background-color-1);
  opacity: 0.95;
}

.container-with-overlay
{
  position: relative;
}

.overlay-over-container
{
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}