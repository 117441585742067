.input-editing,
.input-editing .bp3-input
{
  color: var(--text-color-input);
  background-color: var(--color-input);
  border-width: 0;
  width: var(--input-width);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-align: center;
}



.input-editing,
.input-editing option,
.bp3-control,
.bp3-input,
.bp3-html-select select,
.bp3-button-text {
  font-size: var(--input-font-size);
}

.bp3-label {
  font-size: var(--input-label-font-size);
}

.input-editing .bp3-icon-percentage > svg,
.input-editing .bp3-icon-eye-open > svg,
.input-editing .bp3-icon-eye-off > svg {
  fill: var(--icon-color-input) !important;
}

.bp3-dark .bp3-file-upload-input {
  background-color: var(--color-input);
  color: var(--text-color-input);
}

.bp3-dark .bp3-html-select .bp3-icon,
.bp3-dark .bp3-select .bp3-icon {
  color: var(--icon-color-input);
}

.input-editing-date,
.input-editing-date input,
.input-editing-date .bp3-input,
.input-editing-date .bp3-popover-target {
  color: var(--text-color-input);
  background-color: var(--color-input);
  border-width: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-align: center;
  padding: 0px;
  height: 100%;
  font-size: var(--input-font-size);
}




.bp3-dark .bp3-html-select select,
.bp3-dark .bp3-select select,
.bp3-dark .bp3-html-select select:hover,
.bp3-dark .bp3-select select:hover {
  color: var(--text-color-input);
  background-color: var(--color-input);
  text-align: center;
  text-align-last: center;
}



.smallLabel {
  font-size: var(--small-label-size);
}

.mediumLabel {
  font-size: var(--medium-label-size);
}

.largeLabel {
  font-size: var(--large-label-size);
}

.tight {
  margin: 2px;
  padding: 0;
}


.disabled {
  pointer-events: none;
  opacity: 0.4;
  cursor: not-allowed;
}

.active {
  cursor: pointer;
}


.progressBar
{
  margin-bottom: 15px;
  margin-top: 15px;
}


.progressBar  .bp3-progress-meter
{
  background-color: var(--color-input) !important;
}