.mainProfileContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 2%;
}

.sideBySideCards {
  display: flex;
  justify-content: space-around;
}

.cardContainer {
  margin-left: 5%;
  margin-right: 5%;
  width: 40vw;
}

.line-name-text {
  width: var(--input-width);
  height: var(--input-height);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}


