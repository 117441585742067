.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--background-color-1);
  padding: 50px;
  width: 50%;
  z-index: 1000;
}

.modalContent {
  display: grid;
  text-align: center;
  margin-top: 1vh;
  margin-bottom: 2vh;
}

.modalButtons {
  display: flex;
  justify-content: space-around;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0, 0.7);
  z-index: 1000;
}
