.cardsAndChart {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.scenario-1 .bp3-card {
  background-color: var(--color-scenario-1);
}

.scenario-2 .bp3-card {
  background-color: var(--color-scenario-2);
}

.card-4 p {
  font-size: var(--input-font-size);
}
