.contenedor-tabla {
  padding-left: 0.5%;
  padding-right: 0.5%;
  color: var(--text-color-opaque);
  width: 100%;
}

.flex-tabla {
  border: solid 1px;
  transition: 0.5s;
  border-color: black;
}

.nonFixedTable {
  font-size: var(--normal-font-size);
}

.small-font
{
  font-size: var(--small-font-size);
}

.flex-fila {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.even-row {
  background-color: var(--row-color-1);
}

.uneven-row {
  background-color: var(--row-color-2);
}

.hoverable-row:hover,
.hoverable-row:hover .flex-celda-disabled {
  background-color: var(--color-hover-row);
  color: var(--text-color-hover-row);
}

.header-row {
  color: var(--text-color-header-cell);
  background-color: var(--color-header-cell);
  font-weight: bold;

  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
  -ms-user-select: none;
  user-select: none;

}

.selected-header-cell {
  background-color: var(--color-hover-row);
  color: var(--text-color-hover-row);
}

.flex-fila-margen {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 15px;
  margin-bottom: 15px;
}

.flex-celda {
  display: flex;
  border: var(--cell-border);
  border-color: var(--color-cell-border);
  align-items: center;
  align-content: center;
  justify-content: center;
  flex: 1 100%;
}

.flex-celda .MuiInputBase-root,
.flex-celda .MuiFormControl-root
{
  height: 100%;
}

.flex-celda-double {
  display: flex;
  border: var(--cell-border);
  border-color: var(--color-cell-border);
  align-items: center;
  align-content: center;
  justify-content: center;
  flex: 1 200%;
}

.flex-celda .input-editing,
.flex-celda .input-editing .bp3-input,
.flex-celda .input-editing-date,
.flex-celda .input-editing-date .bp3-input,
.flex-celda .input-editing-date .bp3-popover-target {
  width: 100%;
  height: 100%;
  border: 1px solid;
  border-color: var(--color-cell-border);
  border-radius: 0;
}

.flex-celda-disabled {
  background-color: var(--color-diabled-cell);
  color: var(--text-color-diabled-cell);
}

.command-cell-editing {
  color: var(--text-color-input);
  background-color: var(--color-input);
  border: 1px solid;
  border-color: var(--color-cell-border);
}

.flex-celda > div {
  height: 100%;
  width: 100%;
}

.cell-static-content {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comandos {
  display: flex;
  justify-content: space-evenly;
}

.summary-cell {
  background-color: var(--color-summary-cell);
  color: var(--text-color-summary-cell);
}


/** Warning Cell **/

.hoverable-row:hover .warning-cell,
.summary-row .warning-cell {
  background-color: var(--warning-color-3);
  color: var(--text-color-warning-cell);
}

.even-row .warning-cell {
  background-color: var(--warning-color-1);
  color: var(--text-color-warning-cell);
}

.uneven-row .warning-cell {
  background-color: var(--warning-color-2);
  color: var(--text-color-warning-cell);
}


/** Selected Cell **/
.selected-cell {
  background-color: var(--color-selected-cell);
  color: var(--text-color-selected-cell);
}


.multiple-choice-cell,
.multiple-choice-cell .bp3-form-content,
.multiple-choice-cell .bp3-html-select {
  width: 100%;
  height: 100%;
}

.multiple-choice-cell .input-editing {
  text-align: center;
}

.clickable-row {
  cursor: pointer;
}


/* Tight Tables*/

.tightTable {
  display: flex;
  font-size: var(--input-font-size);
}

.tightColumn {
  display: flex;
  flex-direction: column;
}

.tight-header-cell
{
  color: var(--text-color-header-cell);
  background-color: var(--color-header-cell);
  font-weight: bold;
}

.tight-cell
{
  display: flex;
  border: var(--grid-cell-border);
  border-color: var(--grid-cell-color);
  align-items: center;
  align-content: center;
  justify-content: center; 
  padding-left: 1vh;
  padding-right: 1vh;
  padding-top: 1px;
  padding-bottom: 1px;
  flex-grow: 1;
  white-space: nowrap;
  

}


.tightColumn > .flex-celda > div {
  height: var(--tight-cell-height);
}


.tightColumn .input-editing,
.tightColumn .input-editing .bp3-input,
.tightColumn .input-editing-date input,
.tightColumn .input-editing-date .bp3-input,
.tightColumn .input-editing-date .bp3-popover-target {
  height: var(--tight-cell-height);
  border: 0px;
}

.bordered-column{

  border-color: var(--grid-cell-color);
  border-width: 0px 0px 0px 1px;
  border-style: solid;

}

/** Border Columns **/
.include-border .flex-celda {
  border: var(--grid-cell-border);
  border-color: var(--grid-cell-color);
}


/*Double Cells*/
.double-cell-container
{
  display: flex;
  border-width: 0px;
  border-style: var(--grid-cell-border-style);
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-grow: 2;

}

/*Triple Cells*/
.triple-cell-container
{
  display: flex;
  border-width: 0px;
  border-style: var(--grid-cell-border-style);
  border-color: var(--grid-cell-color);
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-grow: 3;

}

.stacked-cell-container
{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main-container-cell
{
  padding-left: 1vh;
  padding-right: 1vh;
  flex: 1 200%;
  border: var(--grid-cell-border);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.stacked-cell{
  border: var(--grid-cell-border);

}


/*Button Cell*/
.button-cell
{
  height: 1px;
}
.button-cell > button
{
  width: 100%;
  text-align: center;
}

/*Cell Wth Checkbox*/
.cell-with-checkbox
{
  display: flex;
  border: var(--grid-cell-border);
  border-color: var(--grid-cell-color);
  justify-content: center;



}


.cell-with-checkbox-command
{
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  flex: 1 1 19%;
}

.cell-with-checkbox-cell
{
  flex: 1 1 81%;
  height: var(--tight-cell-height);
}

.cell-with-checkbox-cell >.flex-celda
{
  height: 100%;
}



/* Spot Cell*/
.spot-cell
{
  display: flex;
  justify-content: center;
  
}

.cell-with-spot-input
{

}




/* Ajustes puntuales para bluprintjs */
/* --------------------------------- */

/* Ajusta el contenido de la celda */
.contenido-celda > * {
  width: 100%;
}

/* Elimina el margen de los inputs */
.flex-celda > div > div {
  margin: 0px;
}

.sticky
{
  position:-webkit-sticky; 
  position:sticky; 
}
