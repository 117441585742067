.stausLogo {
  margin-left: 12px;
  margin-right: 12px;
}

.inlineDisplay {
  display: flex;
  flex-direction: row;
}

.columnDisplay {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.smalIconContainer {
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
