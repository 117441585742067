.midBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.loginBox {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 10%;
}

.primary-background {
  background-color: var(--primary-color);
}

.stackingBox {
  position: relative;
  height: 100%;
}

.stackedBox-initial {
  height: 100%;
  width: 100%;
}

.stackedBox-next {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.inputUser .inputPwd {
  margin-left: 5%;
  margin-right: 5%;
}

.serverDown {
  pointer-events: none;
  opacity: 0.4;
}

.hidden {
  display: none;
}

.show {
  display: inline;
}
