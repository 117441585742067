.overlay {
  fill: none;
  pointer-events: all;
}

.focusHidden {
  display: none;
  fill: none;
}

.focusShow {
  display: block;
  fill: black;
}
