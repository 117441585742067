.customNavBar {
  display: flex;
  flex-direction: column;
  height: 1em;
  margin: 0;
  padding: 0;
}

.topContainer {
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logos {
  flex-basis: 70% 0 3;
}

.comands {
  flex-basis: 30% 2 0;
}

.parameters {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: var(--text-color-highlighted);
  background-color: var(--background-color-2);
  width: 100%;
  height: 3.5vh;
  padding: 2px;
  font-size: var(--nav-font-size);
}

.parameters > p {
  margin-bottom: 0;
}

.parameters p,
.parameters strong {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.customNavBarGroup {
  margin-top: 0px;
  padding: 0;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.customNavButton {
  color: var(--text-color-highlighted);
  font-size: var(--nav-font-size);
}

.selected,
.selected path {
  background-color: var(--color-selected-link) !important;
  color: var(--text-color-selected-link) !important;
  fill: var(--text-color-selected-link) !important;
}

.ascending :not(strong) {
  color: red;
}

.descending :not(strong) {
  color: green;
}

.neutral :not(strong) {
  color: blue;
}

.customNavSelect select {
  font-size: var(--nav-font-size);
}

.logoImage:hover {
  cursor: pointer;
}

.logoImage {
  margin-left: 15px;
}
