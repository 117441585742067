@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";

:root {
  --primary-color: rgb(238, 172, 24);
  --background-color-1: rgb(19, 23, 34);
  --background-color-2: rgb(34, 40, 56);
  --background-color-3: rgb(57, 75, 89);
  --text-color-highlighted: rgb(238, 238, 238);
  --text-color: rgb(221, 221, 221);
  --text-color-opaque: rgb(214, 214, 214);

  /* Other Colors*/
  --color-1: rgb(238, 172, 24);
  --color-2: rgb(35, 83, 250);
  --bp-dark-color: rgb(57, 75, 89);

  /* Table colors */
  --color-header-cell: rgb(242, 184, 0);
  --text-color-header-cell: rgb(10, 10, 10);
  --color-cell-border: rgb(30, 30, 30);
  --color-table-border: rgb(30, 30, 30);

  /* Table Height */
  --tight-cell-height : 3.5vh;
  --double-tight-cell-height : 7vh;

  /*Cell Borders*/
  --cell-border: 0px solid;
  --grid-cell-border: 0px solid;
  --grid-cell-color : var(--text-color-highlighted);

  --text-color-diabled-cell: rgb(191, 191, 191);
  --color-diabled-cell: rgb(130, 130, 130);

  --color-input: rgb(238, 172, 24);
  --text-color-input: rgb(11, 11, 11);
  --icon-color-input: rgb(20, 20, 20);

  --color-summary-cell: rgb(238, 128, 24);
  --text-color-summary-cell: rgb(19, 23, 34);

  --row-color-1: rgb(30, 32, 40);
  --row-color-2: rgb(64, 69, 83);

  --warning-color-1: rgb(151, 0, 0);
  --warning-color-2: rgb(214, 0, 0);
  --warning-color-3: rgb(214, 39, 0);
  --text-color-warning-cell: rgb(221, 221, 221);

  --color-hover-row: var(--color-summary-cell);
  --text-color-hover-row: var(--text-color-summary-cell);

  --color-selected-cell: var(--color-summary-cell);
  --text-color-selected-cell: var(--text-color-summary-cell);

  /* Selected Colors */
  --color-selected-link: var(--color-input);
  --text-color-selected-link: rgb(11, 11, 11);

  /* Sizes */
  --input-width: calc(50px + 10vh);
  --input-height: 30px;

  /* Font Size */
  --nav-font-size: calc(11px + 0.18vw);
  --input-label-font-size: calc(12px + 0.2vw);
  --input-font-size: calc(12px + 0.15vw);
  --small-label-size: calc(11px + 0.15vw);
  --medium-label-size: calc(11px + 0.2vw);
  --large-label-size: calc(11px + 0.4vw);
  --normal-font-size: calc(6px + 0.6vw);
  --small-font-size: calc(6px + 0.4vw);
  --tiny-font-size : calc(6px + 0.2vw);

  /* Notifications */
  --toastify-color-light: var(--background-color-3) !important;
  --toastify-text-color-light: var(--text-color-highlighted) !important;
  --toastify-color-progress-light: var(--color-input) !important;

  /* Scenarios */
  --color-scenario-1: rgb(50, 114, 167);
  --color-scenario-2: rgb(169, 50, 54);

  /* Increse/ Decrease Colors */
  --color-neutral: blue;
  --color-ascending: red;
  --color-descending: green;


}

.App {
  text-align: center;
  background-color: var(--background-color-1);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

.mainPageWrapper {
  margin-top: 0vh;
}

html {
  background-color: var(--background-color-1);
}

.compact p,
.compact label {
  margin: 1px;
  padding: 0px;
}

.compact h4 {
  margin-top: 2px;
  margin-bottom: 6px;
}


label {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.noCarret {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
