.gridBox {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  
}

.grid-4 {
  flex: 1 0 40%;
  margin: 5px;
  padding: 1px;
}

.grid-2 {
  flex-basis: 50%;
  margin-left: 1vh;
  margin-right: 1vh;
}

.global-view-component {
  border: 1px solid var(--background-color-1);
}

.global-view-component h4 {
  width: 100%;
  cursor: pointer;
}

.global-view-component:hover {
  border: 1px solid var(--primary-color);
}

.pageWithFooter {
  display: flex;
  flex-direction: column;
}

.centered-component
{
  display: flex;
  justify-content: center;
  align-content: center;
}