:root {
}

/* ----------------------------------------------
 * Generated by Animista on 2022-2-25 7:47:28
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@keyframes slide-in-bottom {
  0% {
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in-bottom {
  animation: slide-in-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
   * Generated by Animista on 2022-2-25 7:51:2
   * Licensed under FreeBSD License.
   * See http://animista.net/license for more info. 
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */

/**
   * ----------------------------------------
   * animation slide-out-bottom
   * ----------------------------------------
   */
@keyframes slide-out-bottom {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(1000px);
    opacity: 0;
  }
}

.slide-out-bottom {
  animation: slide-out-bottom 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
